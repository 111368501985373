.recall-modal-success {
  &__title,
  &__subtitle,
  &__content-title {
    color: #fff;
  }

  &__content-subtitle {
    color: var(--white-color-60);
  }

  &__form-wrap {
    position: relative;
    background-color: var(--background-color-dark);
    transition: transform .75s, opacity .75s;
    transition-timing-function: cubic-bezier(.215,.61,.355,1);
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  &._awaiting-animation {
    .recall-modal-success {
      &__form-wrap {
        opacity: 0;
        transform: translate3d(0, 20px, 0);
      }
    }
  }
}

@include respond-up('large') {
  .recall-modal-success {
    padding-top: 25px;

    &__info {
      grid-column: 1/4;
    }

    &__subtitle {
      margin-top: 40px;
    }

    &__form-wrap {
      grid-column: 7/13;
      padding-top: 10px;
      height: calc(var(--grid-column6) / (756 / 440));
    }

    &__content-inner {
      padding: 40px;
    }

    &__content-subtitle {
      margin-top: 20px;
      width: calc(var(--grid-column3) - 40px);
    }
  }
}

@include respond-up('medium') {
  .recall-modal-success {

  }
}

@include respond('medium') {
  .recall-modal-success {
    padding-top: 20px;

    &__content-inner {
      padding: 25px;
    }

    &__subtitle {
      width: var(--grid-column6);
    }

    &__content-subtitle {
      margin-top: 18px;
      width: var(--grid-column6);
    }

    &__info,
    &__form-wrap {
      grid-column: 1/13;
    }

    &__form-wrap {
      margin-top: 50px;
      height: calc(var(--grid-column12) / (720 / 319));
    }
  }
}

@include respond-down('medium') {
  .recall-modal-success {
    &__subtitle {
      margin-top: 20px;
    }
  }
}

@include respond-down('small') {
  .recall-modal-success {
    padding-top: 12px;

    &__content-inner {
      padding: 18px;
    }

    &__content-subtitle {
      margin-top: 10px;
      width: var(--grid-column10);
    }

    &__info,
    &__form-wrap {
      grid-column: 1/11;
    }

    &__form-wrap {
      margin-top: 40px;
      height: calc(var(--grid-column10) / (346 / 319));
    }
  }
}