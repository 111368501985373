:root {
  --errors-color: var(--alert-color, #b50000)
}

form {
  $inputs: 'input[type="text"], input[type="search"], input[type="password"]';

  label {
    //margin-bottom: 2px;
    display: block;
    position: absolute;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 145%;
    letter-spacing: -0.02em;
    color: #fff;
  }

  ::-webkit-input-placeholder {
    opacity: 0;
    pointer-events: none;
  }

  ul.errors {
    margin-top: 2px;
    list-style: none;
    border: 1px solid var(--errors-color);

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    li {
      position: absolute;
      right: 0;
      bottom: 0;

      color: var(--white-color-85);
      background-color: var(--errors-color);
      margin-bottom: 2px;
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 125%;
      letter-spacing: -0.02em;
      padding: 2px 25px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .form-field {
    position: relative;

    &._filled {
      #{$inputs}, textarea {
        + label {
          opacity: 0;
        }
      }
    }
  }

  #{$inputs}, textarea, select {
    border: none;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    padding: 29px 25px;
    width: 100%;
    display: block;
    appearance: none;
    font-family: var(--font);
    background-color: var(--background-color-dark);

    font-weight: 500;
    font-size: 18px;
    line-height: 145%;
    letter-spacing: -0.02em;
    color: #fff;

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px var(--background-color-dark) inset;
      -webkit-text-fill-color: #fff;
    }
  }

  #{$inputs}, select {
    height: var(--default-input-height);
  }

  textarea {
    resize: none;
  //  padding: 5px 10px;
  }

  #{$inputs}, textarea {
    transition: background-color .4s;

    &:focus {
      background-color: var(--background-color-dark-35);

      + label {
        opacity: 0;
      }
    }
  }

  //.button, button {
  //  padding: 0;
  //  margin: 0;
  //  text-align: center;
  //  transition: var(--default-transition);
  //  display: inline-block;
  //  border: none;
  //  background: var(--primary-color);
  //  font-size: 16px;
  //  cursor: pointer;
  //
  //  &.height {
  //    height: var(--default-input-height);
  //  }
  //}
}

@include respond-up('large') {
  :root {
    --default-input-height: 84px;
  }

  form {
    $inputs: 'input[type="text"], input[type="search"], input[type="password"]';

    label {
      top: 29px;
      left: 25px;
    }

    #{$inputs}, textarea, select {
      padding: 29px 25px;
    }

    textarea {
      min-height: 168px;
    }
  }
}

@include respond('medium') {
  form {
    $inputs: 'input[type="text"], input[type="search"], input[type="password"]';

    label {
      top: 18px;
      left: 25px;
    }

    #{$inputs}, textarea, select {
      padding: 18px 25px;
    }
  }
}

@include respond-down('medium') {
  :root {
    --default-input-height: 59px;
  }

  form {
    textarea {
      min-height: 118px;
    }
  }
}

@include respond('small') {
  form {
    $inputs: 'input[type="text"], input[type="search"], input[type="password"]';

    label {
      top: 18px;
      left: 18px;
    }

    #{$inputs}, textarea, select {
      padding: 18px;
    }
  }
}