.index-parallax {
  position: relative;

  &__picture-wrap {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      height: 50%;
      width: 100%;
      z-index: 90;
    }

    &::before {
      background-color: var(--primary-color);
      top: 0;
    }

    &::after {
      background-color: #fff;
      bottom: 0;
    }
  }

  &__picture {
    display: flex;
    overflow: hidden;
    position: relative;
    z-index: 100;
    will-change: transform;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up('large') {
  .index-parallax {
    height: 1500px;

    &__picture-wrap {
      position: sticky;
      height: 100vh;
      top: 0;
    }
  }
}

@include respond-up('medium') {
  .index-parallax {
    --start-width: var(--grid-column12);

    &__picture {
      width: var(--start-width);
    }
  }
}

@include respond('medium') {
  .index-parallax {
    //height: 2000px;

    &__picture-wrap {
      height: 485px;
      //top: calc((100vh - 485px) / 2);

      //&::before {
      //  top: calc((100vh - 485px) / 2 * -1);
      //  height: calc(50% + (100vh - 485px) / 2);
      //}
    }
  }
}

@include respond-down('medium') {
  .index-parallax {

  }
}

@include respond-down('small') {
  .index-parallax {
    --start-width: var(--grid-column10);
    //height: 1000px;

    &__picture-wrap {
      height: 382px;
      //top: calc((100vh - 382px) / 2);

      //&::before {
      //  top: calc((100vh - 382px) / 2 * -1);
      //  height: calc(50% + (100vh - 485px) / 2);
      //}
    }

    &__picture {
      width: var(--grid-column10);
    }
  }
}