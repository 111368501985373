.index-cooperation {
  background-color: var(--primary-color);

  &__description {
    color: #fff;
  }

  &__slider-container {
    display: flex;
    justify-content: center;
  }

  &__main-wrap {
    position: relative;
    z-index: 50;
  }

  &__inner {
    position: relative;
  }

  &__white-bg {
    background-color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 25;
  }

  &__picture-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@include respond-up('large') {
  .index-cooperation {
    padding-top: 160px;

    &__main {
      margin-top: 120px;
    }

    &__description {
      grid-column: 1/6;
      grid-row: 1/2;
    }

    &__form-wrapper {
      grid-column: 7/13;
      grid-row: 1/3;
      padding-bottom: 210px;
    }

    &__slider-container {
      grid-column: 1/5;
      grid-row: 2/3;
      align-items: flex-end;
    }

    &__white-bg {
      height: 50px;
    }

    &__picture-wrap {
      height: calc(var(--grid-column4) / (495 / 408));
    }
  }
}

@include respond('medium') {
  .index-cooperation {
    padding-top: 80px;

    &__main {
      margin-top: 60px;
      grid-row-gap: 0;
    }

    &__description {
      grid-column: 1/11;
    }

    &__form-wrapper {
      grid-column: 1/13;
      margin-top: 50px;
    }

    &__slider-container {
      grid-column: 1/7;
      margin-top: 70px;
    }

    &__white-bg {
      height: 22px;
    }

    &__picture-wrap {
      height: calc(var(--grid-column6) / (354 / 292));
    }
  }
}

@include respond-down('medium') {
  .index-cooperation {

  }
}

@include respond-down('small') {
  .index-cooperation {
    padding-top: 50px;

    &__main {
      margin-top: 40px;
      grid-row-gap: 40px;
    }

    &__description,
    &__form-wrapper {
      grid-column: 1/11;
    }

    &__slider-container {
      grid-column: 2/10;
    }

    &__white-bg {
      height: 37px;
    }

    &__picture-wrap {
      height: calc(var(--grid-column8) / (274 / 226));
    }
  }
}