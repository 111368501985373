.recall-modal {
  &__title {
    color: #fff;
  }

  &__subtitle {
    color: var(--white-color-60);
  }

  &._close {
    form {
      transition: transform .5s, opacity .5s;
      transition-timing-function: cubic-bezier(.215,.61,.355,1);
      opacity: 0;
      transform: translate3d(0, 20px, 0);
    }
  }
}

@include respond-up('large') {
  .recall-modal {
    padding-top: 25px;

    &__info {
      grid-column: 1/4;
    }

    &__form-wrap {
      grid-column: 7/13;
      padding-top: 10px;
    }

    &__subtitle {
      margin-top: 40px;
    }
  }
}

@include respond-up('medium') {
  .recall-modal {

  }
}

@include respond('medium') {
  .recall-modal {
    padding-top: 20px;

    &__info {
      grid-column: 1/7;
    }

    &__form-wrap {
      grid-column: 1/13;
    }

    &__content {
      padding-top: 20px;
      grid-row-gap: 50px;
    }
  }
}

@include respond-down('medium') {
  .recall-modal {
    &__subtitle {
      margin-top: 20px;
    }
  }
}

@include respond-down('small') {
  .recall-modal {
    padding-top: 12px;

    &__info,
    &__form-wrap {
      grid-column: 1/11;
    }

    &__content {
      padding-top: 12px;
      grid-row-gap: 40px;
    }
  }
}