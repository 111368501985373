.index-service {
  border-top: 1px solid var(--primary-color);
  display: flex;
  flex-direction: column;

  &__cta {
    width: max-content;
  }


  &__slider-list {
    will-change: transform;
    backface-visibility: hidden;
  }

  &__examples-title,
  &__description {
    color: var(--gray-color);
  }

  &__examples-list {
    margin-top: 5px;
  }

  &__example {
    margin-top: 4px;

    .button__text {
      color: var(--gray-color);

      &::before {
        border-color: var(--gray-color);
      }
    }
  }

  &__picture {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__nav-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__info {
    display: flex;
  }

  &__examples {
    display: flex;
    flex-direction: column;
  }
}

@include respond-up("large") {
  .index-service {
    padding-top: 20px;

    &__cta {
      margin-top: 35px;
    }

    &__heading {
      margin-top: 45px;
    }

    &__last-description {
      width: calc(var(--grid-column4) - 2 * var(--grid-gap));
    }

    &__info {
      margin: 50px 0 60px;
    }

    &__last-description {
      margin-top: 50px;
    }

    &__images {
      width: calc(var(--grid-column6) - 66px);
      margin-top: auto;
    }

    &__info-left {
      flex: 0 0 var(--grid-column2);
    }

    &__options {
      flex: 0 0 var(--grid-column3);
    }

    &__picture {
      height: calc((350 / 1920) * 100vw);
      width: 100%;
      max-height: 350px;
    }
  }
}

@include respond-up("medium") {
  .index-service {
    &__nav-wrapper {
      margin-top: 12px;
    }

    &__examples {
      margin-top: 30px;
    }

    &__option {
      &:not(:first-child) {
        margin-top: 6px;
      }
    }

    &__info {
      justify-content: space-between;
    }
  }
}

@include respond("medium") {
  .index-service {
    padding-top: 12px;

    &__cta {
      margin-top: 25px;
    }

    &__images {
      margin-top: 50px;
      width: var(--grid-column12);
    }

    &__heading {
      margin-top: 40px;
    }

    &__info,
    &__last-description {
      margin-top: 30px;
    }

    &__info-left {
      flex: 0 0 var(--grid-column4);
    }

    &__options {
      flex: 0 0 var(--grid-column7);
    }

    &__picture {
      height: calc((364 / 768) * 100vw);
      width: calc(var(--grid-column12) + 1px);
    }
  }
}

@include respond-down("medium") {
  .index-service {

  }
}

@include respond-down("small") {
  .index-service {
    padding-top: 6px;

    &__cta {
      margin-top: 30px;
    }

    &__nav-wrapper {
      margin-top: 8px;
    }

    &__images {
      margin-top: 30px;
    }

    &__examples {
      margin-top: 15px;
    }

    &__last-description {
      margin-top: 12px;
    }

    &__heading {
      margin-top: 20px;
    }

    &__info {
      margin-top: 15px;
      flex-direction: column;
    }

    &__options {
      margin-top: 30px;
    }

    &__option {
      &:not(:first-child) {
        margin-top: 4px;
      }
    }

    &__images {
      width: var(--grid-column10);
    }

    &__picture {
      height: calc((182 / 375) * 100vw);
      width: calc(var(--grid-column10) + 1px);
    }
  }
}
