.policy {
  position: relative;

  &__link {
    text-align: start;
  }

  input[type="checkbox"] {
    position: absolute;

    + label {
      position: static;
      padding-left: 34px;
      display: flex;
      flex-wrap: wrap;

      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      letter-spacing: -0.02em;
      color: var(--white-color-60);

      &:before {
        position: absolute;
        content: "";
        width: 18px;
        height: 18px;
        border-radius: 100%;
        border: 1px solid #fff;
        background-color: var(--t);
        transition: background-color .4s;
        left: 0;
        top: 0;
      }
    }

    &:checked + label {
      &:before {
        background-color: #fff;
      }
    }
  }
}

@include respond-up('large') {
  .policy {

  }
}

@include respond-up('medium') {
  .policy {
    input[type="checkbox"] + label {
      font-size: 16px;
    }
  }
}

@include respond('medium') {
  .policy {

  }
}

@include respond-down('medium') {
  .policy {

  }
}

@include respond-down('small') {
  .policy {
    input[type="checkbox"] + label {
      font-size: 14px;
    }
  }
}