.header-nav {
  display: flex;
  align-items: center;
  height: 100%;

  &__title {
    color: #fff;
  }

  &__item {
    height: 100%;
    display: flex;
    overflow: hidden;
  }

  &__link {
    display: flex;
    align-items: center;
    overflow: hidden;

    .button__text {
      @extend .text__main;
    }
  }
}

@include respond-up('large') {
  .header-nav {
    &__link {
      padding: 0 15px;
    }
  }
}

@include respond-up('medium') {
  .header-nav {

  }
}

@include respond('medium') {
  .header-nav {
    &__title {
      font-size: 32px;
    }

    &__link {
      padding: 7px var(--grid-spacer);
    }
  }
}

@include respond-down('medium') {
  .header-nav {
    flex-direction: column;
    align-items: flex-start;

    &__title {
      font-weight: 600;
      line-height: 135%;
    }

    &__link {
      margin: 0 calc(var(--grid-spacer) * -1);
    }
  }
}

@include respond-down('small') {
  .header-nav {
    &__title {
      font-size: 28px;
    }

    &__link {
      padding: 4px var(--grid-spacer);
    }
  }
}