.index-services {
  &__list {
    grid-column-gap: 0;
  }
}

@include respond-up("large") {
  .index-services {
    padding: 160px 0;

    &__list {
      margin-top: 60px;
      grid-row-gap: 120px;
      grid-template-columns: repeat(2, 1fr);
    }

    &__item {
      &:nth-child(odd) {
        border-right: 1px solid var(--primary-color);
        padding-right: 80px;
      }

      &:nth-child(even) {
        padding-left: 80px;
      }
    }
  }
}

@include respond-up("medium") {
  .index-services {

  }
}

@include respond("medium") {
  .index-services {
    padding: 80px 0;

    &__list {
      margin-top: 60px;
      grid-row-gap: 70px;
    }

    &__item {

    }
  }
}

@include respond-down("medium") {
  .index-services {
    &__list {
      grid-template-columns: 1fr;
    }
  }
}

@include respond-down("small") {
  .index-services {
    padding: 50px 0;

    &__list {
      margin-top: 44px;
      grid-row-gap: 40px;
    }
  }
}
