.index-awards {
  background-color: var(--primary-color);

  &__title {
    color: #fff;
  }

  &__description {
    color: var(--white-color-60);
  }

  &__item-icon {
    display: flex;
  }
}

@include respond-up('large') {
  .index-awards {
    padding: 160px 0 200px;

    &__list {
      margin-top: 120px;
      padding-left: calc(var(--grid-column3) + var(--grid-gap));
    }

    &__item-icon {
      margin-bottom: 40px;
    }

    &__title {
      margin-bottom: 18px;
    }
  }
}

@include respond-up('medium') {
  .index-awards {
    &__list {
      grid-template-columns: repeat(3, 1fr);
      padding-bottom: 120px;
    }
  }
}

@include respond('medium') {
  .index-awards {
    padding: 80px 0 120px;

    &__list {
      margin-top: 60px;
    }

    &__item-icon {
      margin-bottom: 25px;
    }

    &__title {
      margin-bottom: 12px;
    }
  }
}


@include respond-down('small') {
  .index-awards {
    padding: 50px 0 60px;

    &__list {
      margin-top: 40px;
      padding-bottom: 50px;
      grid-row-gap: 60px;
      grid-template-columns: 1fr;
    }

    &__item-icon {
      margin-bottom: 18px;
    }

    &__title {
      margin-bottom: 12px;
    }
  }
}