.experience-video-item {
  &__title {
    font-style: normal;
    font-weight: 600;
    line-height: 145%;
    letter-spacing: -0.02em;
    display: block;
  }

  &__image-wrap {
    display: flex;
    width: 100%;
    position: relative;
  }

  &__picture {
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__icon {
    display: flex;
    position: absolute;
    z-index: 25;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    border-radius: 100%;
  }
}

@include respond-up('large') {
  .experience-video-item {
    &__title {
      margin-top: 18px;
      font-size: 16px;
    }

    &__image-wrap {
      height: calc(var(--grid-column6) / (756 / 426));
      max-height: 426px;
    }

    &__icon {
      overflow: hidden;

      svg {
        position: relative;
        z-index: 50;

        path {
          transition: fill .4s ease-in-out;
        }
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        transform: translate3d(-100%, 0, 0);
        transition: transform .4s ease-in-out;
        border-radius: 100%;
      }
    }

    &:hover {
      .experience-video-item__icon {
        &::before {
          transform: translate3d(0, 0, 0);
        }

        svg {
          path {
            fill: var(--primary-color);
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .experience-video-item {

  }
}

@include respond('medium') {
  .experience-video-item {
    &__title {
      margin-top: 14px;
    }

    &__image-wrap {
      height: calc(var(--grid-column6) / (354 / 200));
    }
  }
}

@include respond-down('medium') {
  .experience-video-item {
    &__title {
      font-size: 14px;
    }
  }
}

@include respond-down('small') {
  .experience-video-item {
    &__title {
      margin-top: 12px;
    }

    &__image-wrap {
      height: calc(var(--grid-column10) / (346 / 197));
    }
  }
}