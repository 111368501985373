.text {
  &__heading {
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.02em;
  }

  &__subheading {
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: var(--white-color-60);
  }

  &__big {
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    letter-spacing: -0.02em;
  }

  &__main {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 145%;
    letter-spacing: -0.02em;
  }

  &__small {
    font-style: normal;
    font-weight: 500;
    line-height: 145%;
    letter-spacing: -0.02em;
  }
}

@include respond-up('large') {
  .text {
    &__heading {
      font-weight: 600;
      font-size: 16px;
    }

    &__subheading {
      font-size: 15px;
    }

    &__big {
      font-size: 20px;
    }

    &__main {
      font-size: 18px;
    }

    &__small {
      font-size: 16px;
    }
  }
}

@include respond-down('medium') {
  .text {
    &__heading {
      font-weight: 500;
      font-size: 15px;
    }

    &__subheading {
      font-size: 12px;
    }

    &__big {
      font-size: 18px;
    }

    &__main {
      font-size: 16px;
    }

    &__small {
      font-size: 15px;
    }
  }
}