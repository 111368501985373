.button {
  padding: 0;
  margin: 0;
  text-align: center;
  transition: var(--default-transition);
  background-color: var(--t);
  display: inline-flex;
  border: none;
  cursor: pointer;
  text-decoration: none;
  position: relative;

  &__text-wrap {
    display: flex;
    overflow: hidden;
    position: relative;
  }

  &__text {
    display: block;
  }

  &__icon {
    display: flex;
  }

  &_expand, &_wide {
    width: 100%;
  }

  &_round {
    border-radius: 100px;
  }

  &_primary {
    border: 1px solid #fff;
    border-radius: 50px;

    .button__text {
      color: #fff;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      letter-spacing: -0.02em;
    }
  }

  &_secondary {
    @extend .button_primary;
    border-color: var(--primary-color);

    .button__text {
      color: var(--primary-color);
    }
  }

  &_arrow {
    height: 100%;
    width: max-content;
    display: flex;
    align-items: center;

    .button {
      &__text {
        color: #fff;
        margin-right: 6px;
      }
    }
  }

  &_bordered {
    .button__text {
      color: #fff;
    }
  }

  &_hovered {
    @extend .button_bordered;
  }
}

@include respond-up('large') {
  .button {
    &_primary {
      padding: 15px 50px;
      position: relative;
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background-color: #fff;
        border-radius: 50px;
        width: 0;
        height: 100%;
        transition: width .4s ease-in-out;
      }

      .button__text {
        color: #fff;
        transition: transform .4s ease-in-out;
        font-size: 18px;

        &:last-child {
          position: absolute;
          bottom: 0;
          left: 0;
          transform: translate3d(0, 100%, 0);
          color: var(--primary-color);
        }
      }

      &:hover {
        &::before {
          width: 100%;
        }

        .button__text {
          &:first-child {
            transform: translate3d(0, -100%, 0);
          }

          &:last-child {
            transform: translate3d(0, 0, 0);
          }
        }
      }
    }

    &_secondary {
      .button__text {
        color: var(--primary-color);

        &:last-child {
          color: #fff;
        }
      }

      &::before {
        background-color: var(--primary-color);
      }
    }

    &_arrow {
      transition: all .6s cubic-bezier(.55, 0, .1, 1);
      overflow: hidden;

      .button__icon {
        transition: transform .6s cubic-bezier(.55, 0, .1, 1);
        overflow: hidden;

        //svg {
        //  width: 18px;
        //  height: 12px;
        //}

        &_left {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate3d(calc(-100% - 6px), calc(-50% + 1px), 0);
        }

        &_right {
          padding-top: 2px;

          svg {
            transition: transform .6s cubic-bezier(.55, 0, .1, 1);
          }
        }
      }

      &:hover {
        padding-left: 24px;

        .button {
          &__icon {
            &_left {
              transform: translate3d(0, calc(-50% + 1px), 0);
            }

            &_right {
              svg {
                transform: translate3d(100%, 0, 0);
              }
            }
          }
        }
      }
    }

    &_bordered {
      .button__text {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          bottom: -2px;
          left: 0;
          width: 100%;
          border-bottom: 1px solid #fff;
          transform: scaleX(0);
          transform-origin: center right;
          transition: transform .4s;
        }
      }

      &:hover {
        .button__text {
          &::before {
            transform: scaleX(1);
            transform-origin: left center;
          }
        }
      }
    }

    &_hovered {
      .button__text {
        &::before {
          transform: scaleX(1);
          transform-origin: left center;
        }
      }

      &:hover {
        .button__text {
          &::before {
            transform: scaleX(0);
            transform-origin: center right;
          }
        }
      }
    }
  }
}

@include respond-down('medium') {
  .button {
    &_primary {
      padding: 13px 35px;

      .button__text {
        font-size: 16px;

        &:last-child {
          display: none;
        }
      }
    }

    &_arrow {
      .button {
        &__icon_left {
          display: none;
        }

        //&__icon {
        //  svg {
        //    width: 16px;
        //    height: 10px;
        //  }
        //}
      }
    }
  }
}