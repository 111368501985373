/*
spacer - Задаёт фиксированные отступы по краям контейнера.

Использование в шаблоне:

<div class="container container_spacer">
  ...
</div>

По умолчанию в качестве единиц измерения для отступа используются - 'px'.
При необходимости их можно изменить переменной $spacer-unit в файлике ../_common/_container.scss
*/

$grid: (
  small: (
    from: 0,
    to: 767
  ),
  medium: (
    from: 768,
    to: 1199
  ),
  large: (
    from: 1200
  )
);

:root {
  --body-font-size: 16px;
  --body-line-height: 1.3;

  --font: "Manrope", sans-serif;
  --h-font: "Manrope", sans-serif;

  --t: transparent;

  --primary-color: #181919;
  --alert-color: #801C1C;

  --default-transition: all 0.4s var(--default-transition-function);
  --default-transition-function: ease-in-out;

  --primary-color-opacity: rgba(1, 199, 103, .85);

  --background-color-dark: #282929;
  --background-color-gray: #3E3E3E;

  --gray-color: #717272;
  --purple-color: #8E62AB;
  --green-color-whatsapp: #25D366;
  --blue-color-telegram: #37AEE2;

  --blue-green-color-1: #003549; // endeka
  --blue-green-color-2: #004766; // domureki
  --blue-green-color-3: #0E485E; // riverpark

  --blue-color-1: #002148; // ksm
  --blue-color-2: #01205C; // kssk
  --blue-color-3: #041F40; // ksm-lk

  --green-color-1: #038381; // skandinavia
  --green-color-2: #27542D; // timiryasev
  --green-color-3: #0D5950; // ozerki
  --green-color-4: #0C3939; // life

  --red-color-1: #82081A; // kaiser
  --red-color-2: #63290E; // ciolkovsky

  --brown-color-1: #251F1F; // kssk history
  --brown-color-2: #0A0909; // dom40
  --brown-color-3: #100209; // hlyinov
  --brown-color-4: #3C2619; // horizont
  --brown-color-5: #482E27; // terem
  --brown-color-6: #3C3226; // slobodskoi

  // цвета с прозначностью
  --white-color-07: rgba(255, 255, 255, 0.07);
  --white-color-40: rgba(255, 255, 255, 0.4);
  --white-color-60: rgba(255, 255, 255, 0.6);
  --white-color-85: rgba(255, 255, 255, 0.85);
  --primary-color-40: rgba(24, 25, 25, 0.4);
  --primary-color-60: rgba(24, 25, 25, 0.6);

  --background-color-dark-35: rgba(40, 41, 41, 0.35);

  --blue-green-color-1-60: rgba(0, 53, 73, .6); // endeka
  --blue-green-color-2-60: rgba(0, 71, 102, .6); // domureki
  --blue-green-color-3-60: rgba(14, 72, 94, .6); // riverpark

  --blue-color-1-60: rgba(0, 33, 72, .6); // ksm
  --blue-color-2-60: rgba(1, 32, 92, .6); // kssk
  --blue-color-3-60: rgba(4, 31, 64, .6); // ksm-lk

  --green-color-1-60: rgba(3, 131, 129, .6); // skandinavia
  --green-color-2-60: rgba(39, 84, 45, .6); // timiryasev
  --green-color-3-60: rgba(13, 89, 80, .6); // ozerki
  --green-color-4-60: rgba(12, 57, 57, .6); // life

  --red-color-1-60: rgba(130, 8, 26, .6); // kaiser
  --red-color-2-60: rgba(99, 41, 14, .6); // ciolkovsky

  --brown-color-1-60: rgba(37, 31, 31, .6); // kssk history
  --brown-color-2-60: rgba(10, 9, 9, .6); // dom40
  --brown-color-3-60: rgba(16, 2, 9, .6); // hlyinov
  --brown-color-4-60: rgba(60, 38, 25, .6); // horizont
  --brown-color-5-60: rgba(72, 46, 39, .6); // terem
  --brown-color-6-60: rgba(60, 50, 38, .6); // slobodskoi
}
