.hamburger {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-right: calc(var(--grid-spacer) * -1);
  padding: 0 var(--grid-spacer);

  &__inner {
    position: relative;
    height: 10px;
  }

  &__line {
    position: absolute;
    left: 0;
    height: 2px;
    background-color: #fff;

    &_top {
      top: 0;
    }

    &_bottom {
      bottom: 0;
    }
  }

  &._opened {
    .hamburger {
      &__line {
        &_top {
          animation: top-in .8s forwards;
        }

        &_bottom {
          animation: bottom-in .8s forwards;
        }
      }
    }
  }

  &._closing {
    .hamburger {
      &__line {
        &_top {
          animation: top-out .8s forwards;
        }

        &_bottom {
          animation: bottom-out .8s forwards;
        }
      }
    }
  }
}

@include respond-up('large') {
  .hamburger {

  }
}

@include respond-up('medium') {
  .hamburger {
    &__inner,
    &__line {
      width: 32px;
    }
  }
}

@include respond('medium') {
  .hamburger {

  }
}

@include respond-down('medium') {
  .hamburger {

  }
}

@include respond-down('small') {
  .hamburger {
    &__inner,
    &__line {
      width: 28px;
    }
  }
}

@keyframes top-in {
  from {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, 4px, 0);
  }
  to {
    transform: translate3d(0, 4px, 0) rotate(45deg);
  }
}

@keyframes top-out {
  from {
    transform: translate3d(0, 4px, 0) rotate(45deg);
  }
  50% {
    transform: translate3d(0, 4px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bottom-in {
  from {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, -4px, 0);
  }
  to {
    transform: translate3d(0, -4px, 0) rotate(-45deg);
  }
}

@keyframes bottom-out {
  from {
    transform: translate3d(0, -4px, 0) rotate(-45deg);
  }
  50% {
    transform: translate3d(0, -4px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}