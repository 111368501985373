.index-experience {
  background-color: #fff;

  &__button-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__main {
    grid-row-gap: 0;
  }

  &__video-items {
  }
}

@include respond-up('large') {
  .index-experience {
    padding: 160px 0;

    &__inner {
      margin-top: 120px;
    }

    &__description {
      grid-column: 1/6;
    }

    &__button-wrap {
      grid-column: 1/4;
    }

    &__items {
      grid-column: 7/13;
      grid-row: 1/3;
    }

    &__button {
      margin-top: 40px;
    }

    &__video-items {
      margin-top: 120px;
    }
  }
}

@include respond-up('medium') {
  .index-experience {
    &__button-wrap {
      justify-content: flex-end;
    }

    &__video-items {
      grid-column: 1/13;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@include respond('medium') {
  .index-experience {
    padding: 80px 0;

    &__inner {
      margin-top: 60px;
    }

    &__main {
      grid-row-gap: 50px;
    }

    &__description {
      grid-column: 1/9;
    }

    &__button-wrap {
      grid-column: 1/5;
    }

    &__items {
      grid-column: 6/13;
    }

    &__button {
      margin-top: 30px;
    }

    &__video-items {
      margin-top: 30px;
    }
  }
}

@include respond-down('medium') {
  .index-experience {

  }
}

@include respond-down('small') {
  .index-experience {
    padding: 50px 0;

    &__inner {
      margin-top: 40px;
    }

    &__main {
      grid-row-gap: 40px;
    }

    &__description,
    &__button-wrap,
    &__items,
    &__video-items {
      grid-column: 1/11;
    }

    &__description {
      grid-row: 1;
    }

    &__items {
      grid-row: 2;
    }

    &__button-wrap {
      grid-row: 3;
    }

    &__button {
      margin-top: 25px;
    }

    &__video-items {
      grid-template-columns: 1fr;
      grid-row-gap: 29px;
    }
  }
}