.index-hero {
  position: relative;
  background-color: var(--primary-color);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: var(--primary-color);
    transition: height 1.5s cubic-bezier(.645,.045,.355,1);
    z-index: 600;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    transform: translate3d(0, 0, 0);
    transition: transform 1.6s cubic-bezier(.645,.045,.355,1);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(24, 24, 24, 0.38) 0%, #181818 100%);
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__inner {
    position: relative;
    z-index: 500;
    height: 100vh;
    width: 100%;
  }

  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 120px;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: #fff;
    width: 100%;
  }

  &__title-inner {
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    display: block;

    span {
      transition: transform 1s cubic-bezier(.645,.045,.355,1);
      transform: translate3d(0, -7%, 0);
      will-change: transform;
    }

    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        span {
          transition-delay: calc(.3s + (#{$i} * .1s));
        }
      }
    }
  }

  &__description {
    color: #fff;
  }

  &__description-wrap {
    transition: transform 1s .8s, opacity 1s .8s;
    transition-timing-function: cubic-bezier(.645,.045,.355,1);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  &.need-animation {
    &::before {
      height: 100%;
    }

    .index-hero {
      &__picture {
        transform: translate3d(0, 20%, 0);
      }

      &__title-inner {
        span {
          transform: translate3d(0, 100%, 0);
        }
      }

      &__description-wrap {
        transform: translate3d(0, 20px, 0);
        opacity: 0;
      }
    }
  }
}

@include respond-up('large') {
  .index-hero {
    --hero-height: max(100vh, 768px);

    &__title {
      font-size: clamp(74px, calc((119 / 1920) * 100vw), 119px);
    }

    &__button {
      margin-top: 40px;
    }

    &__description-wrap {
      width: var(--grid-column3);
      min-width: 364px;
      margin-left: var(--grid-column3);
    }

    &__picture {
      height: var(--hero-height);
    }

    &__inner {
      padding-top: 180px;
      height: var(--hero-height);
    }
  }
}

@include respond-up('medium') {
  .index-hero {
    &__description-wrap {
      margin-top: 100px;
    }
  }
}

@include respond('medium') {
  .index-hero {
    &__title {
      font-size: 70px;
    }

    &__button {
      margin-top: 35px;
    }

    &__description-wrap {
      width: var(--grid-column6);
      margin-left: var(--grid-column4);
    }

    &__inner {
      padding-top: 217px;
    }
  }
}

@include respond-down('medium') {
  .index-hero {

  }
}

@include respond-down('small') {
  .index-hero {
    &__title {
      font-size: 38px;
    }

    &__button {
      margin-top: 30px;
    }

    &__description-wrap {
      width: var(--grid-column10);
      margin-top: 36px;
    }

    &__inner {
      padding-top: 168px;
    }
  }
}