.index-page {
  &__services,
  &__agency,
  &__awards,
  &__reviews,
  &__experience {
    position: relative;
    z-index: 200;
  }
}

@include respond-up("large") {
  .index-page {

  }
}

@include respond-up("medium") {
  .index-page {
  }
}

@include respond("medium") {
  .index-page {

  }
}

@include respond-down("medium") {
  .index-page {

  }
}

@include respond-down("small") {
  .index-page {

  }
}
