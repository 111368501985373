.header-drop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  overflow: hidden;
  display: flex;
  z-index: 1000;
  height: 0;
  transition: height 1s cubic-bezier(.645,.045,.355,1) .15s;
  background-color: var(--primary-color);

  &__inner {
    padding-top: calc(var(--header-height) + var(--header-drop-padding));
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
  }

  &__top {
    flex: 0 0 auto;
    display: flex;
    justify-content: flex-end;
  }

  &__main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 0 auto;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      border-top: 1px solid #fff;
      transform-origin: left center;
      transition: transform .95s cubic-bezier(.215,.61,.355,1) .15s;
      transform: scaleX(0);
    }
  }

  &__phone {
    padding: 15px var(--grid-spacer);
    margin: 0 calc(var(--grid-spacer) * -1);
    opacity: 0;
    transform: translate3d(0, 20px, 0);
    transition: opacity .25s linear .2s,transform .45s cubic-bezier(.55,.085,.68,.53);
  }

  &__socials {
    overflow: hidden;
    opacity: 0;
    transform: translate3d(0, 20px, 0);
    transition: opacity .25s linear .2s,transform .45s cubic-bezier(.55,.085,.68,.53);
  }

  &__nav {
    height: unset;

    .header-nav {
      &__link {
        transform: translate3d(0, 100%, 0);
        transition: transform .35s cubic-bezier(.55,.055,.675,.19);
      }

      &__item {
        @for $i from 1 through 10 {
          &:nth-last-child(#{$i}) {
            .header-nav__link {
              transition-delay: calc(#{$i} * .1s);
            }
          }
        }
      }
    }
  }

  &._opened {
    transition: height 1.3s cubic-bezier(.645,.045,.355,1);
    height: 100vh;

    .header-nav {
      &__item {
        @for $i from 1 through 10 {
          &:nth-child(#{$i}) {
            .header-nav__link {
              transition-delay: calc(.25s + (#{$i} * .1s));
            }
          }
        }
      }

      &__link {
        transition: transform .75s cubic-bezier(.215,.61,.355,1);
        transform: translate3d(0, 0, 0);
      }
    }

    .header-drop {
      &__main {
        &::before {
          transition-delay: .6s;
          transform: scaleX(1);
        }
      }

      &__socials,
      &__phone {
        transition: opacity .45s linear,transform .75s cubic-bezier(.215,.61,.355,1);
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }

      &__phone {
        transition-delay: .6s;
      }

      &__socials {
        transition-delay: .75s;
      }
    }
  }
}

@include respond-up('large') {
  .header-drop {
    display: none;
  }
}

@include respond-up('medium') {
  .header-drop {
    &__top {
      display: none;
    }
  }
}

@include respond('medium') {
  .header-drop {
    --header-drop-padding: 84px;

    &__inner {
      padding-bottom: 43px;
    }

    &__nav {
      margin-top: 3px;
    }
  }
}

@include respond-down('medium') {
  .header-drop {

  }
}

@include respond-down('small') {
  .header-drop {
    --header-drop-padding: 56px;

    &__inner {
      padding-bottom: 29px;
    }

    &__nav {
      margin-top: 11px;
    }
  }
}