.arrow {
  display: flex;

  &__icon {
    display: flex;
    overflow: hidden;

    svg {
      width: 100%;
      height: 100%;
      transition: transform .4s;
    }
  }

  &_right {
    .arrow__icon {
      transform: rotate(180deg);
    }
  }
}

@include respond-up('large') {
  .arrow {
    &:hover {
      .arrow__icon svg {
        animation: arrow-animation .5s;
      }
    }
  }
}

@include respond-up('medium') {
  .arrow {
    &__icon {
      width: 48px;
      height: 34px;
    }
  }
}

@include respond('medium') {
  .arrow {

  }
}

@include respond-down('medium') {
  .arrow {

  }
}

@include respond-down('small') {
  .arrow {
    &__icon {
      width: 34px;
      height: 25px;
    }
  }
}

@keyframes arrow-animation {
  from {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(-100%, 0, 0);
  }
  51% {
    transform: translate3d(100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}