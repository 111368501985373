h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
  font-family: var(--h-font);
  font-size: var(--h-size);
  font-style: normal;
  letter-spacing: -0.02em;
}

h1, .h1, h2, .h2, h3, .h3, h5, .h5 {
  font-weight: 600;
}

h4, .h4 {
  font-weight: 500;
}

h1, .h1 {
  line-height: 120%;
}

h3, .h3 {
  line-height: 135%;
}

h4, .h4, h5, .h5 {
  line-height: 140%;
}

@include respond-up('large') {
  h1, .h1 {
    --h-size: clamp(70px, calc((70 / 1200) * 100vw), 100px);
  }

  h2, .h2 {
    --h-size: clamp(45px, calc((45 / 1200) * 100vw), 55px);
  }

  h3, .h3 {
    --h-size: clamp(26px, calc((26 / 1200) * 100vw), 32px);
  }

  h4, .h4 {
    --h-size: 24px;
  }

  h5, .h5 {
    --h-size: 22px;
  }
}

@include respond('medium') {
  h1, .h1 {
    --h-size: 70px;
  }

  h2, .h2 {
    --h-size: 45px;
  }

  h3, .h3 {
    --h-size: 24px;
  }

  h4, .h4 {
    --h-size: 20px;
  }

  h5, .h5 {
    --h-size: 18px;
  }
}

@include respond-down('medium') {
  h1, .h1 {
    line-height: 110%;
  }
}

@include respond('small') {
  h1, .h1 {
    --h-size: 38px;
  }

  h2, .h2 {
    --h-size: 28px;
  }

  h3, .h3 {
    --h-size: 20px;
  }

  h4, .h4 {
    --h-size: 18px;
    line-height: 120%;
  }

  h5, .h5 {
    --h-size: 17px;
  }
}