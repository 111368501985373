.index-agency {
  &__top {
    background-color: var(--primary-color);
  }

  &__description {
    &_main {
      color: #fff;
    }

    &_left,
    &_right {
      color: var(--white-color-60);
    }
  }

  &__picture {
    display: flex;
    overflow: hidden;

    &_top {
      width: 100vw;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up('large') {
  .index-agency {
    &__top {
      padding-top: 160px;
    }

    &__top-texts-wrap {
      padding: 120px 0;
    }

    &__description {
      &_main {
        grid-column: 1/6;
      }

      &_left {
        grid-column: 7/10;
      }

      &_right {
        grid-column: 10/13;
      }
    }

    &__picture {
      &_top {
        height: calc((820 / 1920) * 100vw);
        max-height: 820px;
      }
    }
  }
}

@include respond-up('medium') {
  .index-agency {

  }
}

@include respond('medium') {
  .index-agency {
    &__top {
      padding-top: 80px;
    }

    &__top-texts-wrap {
      padding: 60px 0 70px;
    }

    &__description {
      &_main {
        grid-column: 1/11;
      }

      &_left {
        grid-column: 1/7;
      }

      &_right {
        grid-column: 7/13;
      }
    }

    &__top-texts {
      grid-row-gap: 40px;
    }

    &__picture {
      &_top {
        height: calc((328 / 768) * 100vw);
      }
    }
  }
}

@include respond-down('medium') {
  .index-agency {

  }
}

@include respond-down('small') {
  .index-agency {
    &__top {
      padding-top: 50px;
    }

    &__top-texts-wrap {
      padding: 40px 0;
    }

    &__description {
      grid-column: 1/11;
    }

    &__top-texts {
      grid-row-gap: 30px;
    }

    &__picture {
      &_top {
        height: calc((238 / 374) * 100vw);
      }
    }
  }
}