.socials {
  &__list {
    display: flex;
    align-items: center;
    margin: 0 -4px;
  }

  &__link {
    display: flex;
    padding: 4px;
    position: relative;
  }

  &__icon {
    display: inline-flex;

    svg {
      path {
        transition: fill .4s;
      }
    }
  }
}

@include respond-up('large') {
  .socials {
    &__link {
      &:hover {
        svg {
          path {
            fill: var(--white-color-40);
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .socials {

  }
}

@include respond('medium') {
  .socials {

  }
}

@include respond-down('medium') {
  .socials {

  }
}

@include respond-down('small') {
  .socials {

  }
}