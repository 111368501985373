:root{
  --modal-bg: var(--t);
  --modal-content-radius: 5px;
  --modal-content-shadow: 3px 4px 35px rgba(92, 99, 100, 0.27);
  --modal-content-padding: 0;
  --modal-content-background: var(--modal-bg);
  --modal-closer-color: var(--modal-bg);
  --modal-closer-size: 30px;
  --modal-preloader-size: 30px;
}

.modal__bg {
  position: fixed;
  height: 100%;
  width: 100%;
  background: var(--modal-bg);
  z-index: 10000;
  display: none;
  top: 0;
  left: 0;
  overflow-y: scroll;

  &.opened {
    display: block;
  }
}

.modal__layout {
  display: none;
  position: relative;
  z-index: 11000;
  cursor: default;
  //padding: 80px 0;
  margin: 0 auto;
  min-height: 100%;
  align-items: center;
  justify-content: center;

  &.opened {
    display: flex;

    .modal__main_form {
      &:not(.modal__main_errors) {
        .modal__main-inner {
          height: 0;
          animation: height-in 1s cubic-bezier(.645,.045,.355,1) .15s forwards;

          &::before {
            animation: border-in .75s cubic-bezier(.215,.61,.355,1) .55s forwards;
            transform: scaleX(0);
          }
        }
      }
    }

    .project-modal {
      &__top {
        &::before {
          animation: border-in .75s cubic-bezier(.215,.61,.355,1) .55s forwards;
        }
      }

      &__subtitle {
        animation: translate-opacity-in .75s cubic-bezier(.215,.61,.355,1) .45s forwards;
      }

      &__title {
        animation: translate-in .75s cubic-bezier(.215,.61,.355,1) .55s forwards;
      }

      &__top-link {
        animation: translate-opacity-in .75s cubic-bezier(.215,.61,.355,1) .35s forwards;
      }

      &__images {
        animation: images-in .75s cubic-bezier(.215,.61,.355,1) .65s forwards;
      }
    }

    .modal__main {
      &:not(.modal__main_errors) {
        .recall-modal {
          &__title,
          &__subtitle,
          &__form-wrap {
            transform: translate3d(0, 20px, 0);
            opacity: 0;
            animation: translate-opacity-in .75s cubic-bezier(.215, .61, .355, 1) .65s forwards;
          }
        }
      }
    }
  }
}

.modal__container {
  padding: var(--modal-content-padding);
  border-radius: var(--modal-content-radius);
  background: var(--modal-content-background);
  box-shadow: var(--modal-content-shadow);
  position: relative;

  &._close {
    .modal__closer {
      opacity: 0 !important;
    }

    .modal__main_form:not(.modal__main_errors),
    .modal__main_success {
      .modal__main-inner {
        height: 100vh;
        animation: height-out 1s cubic-bezier(.645, .045, .355, 1) .15s forwards;

        &::before {
          animation: border-out .65s cubic-bezier(.55, .055, .675, .19) forwards;
          transform: scaleX(1);
        }
      }
    }

    .project-modal {
      &__top {
        &::before {
          animation: border-out .65s cubic-bezier(.55,.055,.675,.19) forwards;
        }
      }

      &__subtitle {
        animation: translate-opacity-out .35s cubic-bezier(.55,.055,.675,.19) forwards;
      }

      &__title{
        animation: translate-out .35s cubic-bezier(.55,.055,.675,.19) forwards;
      }

      &__top-link {
        animation: translate-opacity-out .35s cubic-bezier(.55,.055,.675,.19) forwards;
      }

      &__images {
        animation: images-out .35s cubic-bezier(.55,.055,.675,.19) forwards;
      }
    }

    .modal__main {
      &:not(.modal__main_errors) {
        .recall-modal,
        .recall-modal-success {
          &__title,
          &__subtitle,
          &__form-wrap {
            animation: translate-opacity-out .35s cubic-bezier(.55,.055,.675,.19) forwards;
          }
        }
      }
    }
  }
}

.modal__main {
  width: 100vw;
  height: 100vh;
}

.modal__main-inner {
  overflow: hidden;
  background-color: var(--primary-color);
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: var(--modal-closer-width);
    width: 100%;
    border-bottom: 1px solid #fff;
  }
}

.modal__content-wrap {
  margin-top: calc(var(--modal-closer-width) + 1px);
  height: calc(100vh - (var(--modal-closer-width) - 1px));
  overflow-y: scroll;
}

.modal__content {
  img {
    max-width: 100%;
    height: auto;
  }
}

.modal__closer {
  position: absolute;
  z-index: 200;
  top: 0;
  right: var(--grid-spacer-and-indent);
  width: var(--modal-closer-width);
  height: var(--modal-closer-width);
  margin-right: var(--modal-closer-mr);

  font-size: var(--modal-closer-size);
  color: var(--modal-closer-color);

  text-decoration: none;
  cursor: pointer;

  background: {
    repeat: no-repeat;
    position: center;
  };
}

.modal__loader {
  display: block;
  position: fixed;
  z-index: 3000;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: var(--modal-bg);
  transition: 0.3s all;
  opacity: 0;
  visibility: hidden;
}

body.modal-loading {
  overflow: hidden;
  --primary-color-opacity: rgba(1, 199, 103, .85);
  --preloader-size: 100px;

  .modal__loader {
    opacity: 1;
    visibility: visible;

    &:before, &:after {
      content: '';
      position: fixed;
      width: var(--preloader-size);
      height: var(--preloader-size);
      left: 50%;
      top: 50%;
      margin-left: calc(var(--preloader-size)/-2);
      margin-top: calc(var(--preloader-size)/-2);
      background-color: #000;
      border-radius: calc(var(--preloader-size)/2);
      transform: scale(0);
    }

    &:before {
      background-color: var(--primary-color-opacity, var(--primary-color));
      animation-name: pulse;
      animation-iteration-count: infinite;
      animation-duration: 1.4s;
      animation-delay: 0.4s;
    }

    &:after {
      background-color: var(--primary-color);
      animation-name: pulse;
      animation-iteration-count: infinite;
      animation-duration: 1.4s;
    }
  }
}

@include respond-up('large') {
  :root {
    --modal-closer-width: 92px;
    --modal-closer-mr: calc((92px - 25px) / 2 * -1)
  }
}

@include respond-up('medium') {
  .modal__closer {
    background: {
      image: url('../images/svg/closer.svg');
    };
  }
}


@include respond('medium') {
  :root {
    --modal-closer-width: 56px;
    --modal-closer-mr: calc((56px - 25px) / 2 * -1)
  }
}

@include respond-down('small') {
  :root {
    --modal-closer-width: 44px;
    --modal-closer-mr: calc((44px - 22px) / 2 * -1)
  }

  .modal__closer {
    background: {
      image: url('../images/svg/closer-small.svg');
    };
  }
}

@keyframes pulse {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes translate-in {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes translate-out {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes translate-opacity-in {
  0% {
    transform: translate3d(0, 20px, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes translate-opacity-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 20px, 0);
    opacity: 0;
  }
}

@keyframes images-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes images-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes border-in {
  0% {
    transform: scaleX(0);
    transform-origin: left center;
  }
  100% {
    transform: scaleX(1);
    transform-origin: left center;
  }
}

@keyframes border-out {
  0% {
    transform: scaleX(1);
    transform-origin: center right;
  }
  100% {
    transform: scaleX(0);
    transform-origin: center right;
  }
}

@keyframes height-in {
  0% {
    height: 0;
  }
  100% {
    height: 100vh;
  }
}

@keyframes height-out {
  0% {
    height: 100vh;
  }
  100% {
    height: 0;
  }
}