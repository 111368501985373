.layout {
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  flex-direction: column;
}

@include respond-up('large') {
  .layout {
    --header-height: 92px;
  }
}

@include respond('medium') {
  .layout {
    --header-height: 56px;
  }
}

@include respond('small') {
  .layout {
    --header-height: 44px;
  }
}