.awards-certificates {
  border-top: 1px solid #fff;

  &__top {
    display: flex;
    align-items: flex-start;
  }

  &__top-title,
  &__top-description {
    color: #fff;
  }

  &__item-icon-wrap {
    background-color: var(--white-color-07);
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 0 auto;
  }

  &__item-title {
    color: #fff;
  }

  &__item {
    display: flex;
    flex-direction: column;
  }

  &__item-title {
    flex: 0 0 auto;
  }
}

@include respond-up('large') {
  .awards-certificates {
    padding-top: 26px;

    &__top {
      grid-column: 1/10;
      justify-content: space-between;
    }

    &__top-title {
      padding-top: 9px;
    }

    &__top-description {
      width: var(--grid-column6);
    }

    &__item-title {
      margin-top: 18px;
    }

    &__inner {
      grid-row-gap: 80px;
    }

    &__items {
      grid-row-gap: 85px;
    }

    &__item {
      grid-column: span 3;
      height: calc(var(--grid-column3) / (364 / 278));

      &:first-child {
        grid-column: 4/7
      }

      &:nth-child(2) {
        grid-column: 7/13;
        height: calc(var(--grid-column6) / (756 / 435));
      }
    }

    &__item-icon-wrap {
      svg {
        width: calc(var(--grid-column3) - 54px);
      }
    }
  }
}

@include respond-up('medium') {
  .awards-certificates {
    &__items {
      grid-column: 1/13;
    }
  }
}

@include respond('medium') {
  .awards-certificates {
    padding-top: 12px;

    &__top-description {
      margin-top: 25px;
    }

    &__item-title {
      margin-top: 14px;
    }

    &__inner {
      grid-row-gap: 60px;
    }

    &__items {
      grid-row-gap: 67px;
    }

    &__item {
      grid-column: span 4;
      height: calc(var(--grid-column4) / (232 / 185));

      &:nth-child(2) {
        grid-column: 5/13;
        height: calc(var(--grid-column8) / (476 / 281));
      }
    }

    &__item-icon-wrap {
      svg {
        width: calc(var(--grid-column4) - 40px);
      }
    }
  }
}

@include respond-down('medium') {
  .awards-certificates {
    &__top {
      grid-column: 1/11;
      flex-direction: column;
    }
  }
}

@include respond-down('small') {
  .awards-certificates {
    padding-top: 6px;

    &__top-description {
      margin-top: 40px;
    }

    &__inner {
      grid-row-gap: 40px;
    }

    &__items {
      grid-column: 1/11;
      grid-row-gap: 19px;
    }

    &__item-title {
      margin-top: 12px;
    }

    &__item {
      grid-column: span 5;
      height: calc(var(--grid-column5) / (168 / 141));
    }

    &__item-icon-wrap {
      svg {
        width: calc(var(--grid-column5) - 40px);
      }
    }
  }
}