.project-card {
  cursor: default;

  &_has-modal-content {
    cursor: pointer;
  }

  &__title {
    color: #fff;
    display: block;
  }

  &__subtitle {
    color: var(--white-color-60);
    margin-top: 4px;
    display: block;
  }

  &__image-wrap {
    position: relative;
    overflow: hidden;
    display: block;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 100;
  }

  &__picture {
    display: flex;

    &_bg {
      width: 100%;
      height: 100%;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
    opacity: .6;
    transition: opacity .5s linear;
  }

  &__bottom {
    display: block;
  }
}

@include respond-up('large') {
  .project-card {
    &__image-wrap {
      height: calc((238 / 1920) * 100vw);
      max-height: 238px;
    }

    &__icon {
      width: calc((250 / 1920) * 100vw);
      height: calc((120 / 1920) * 100vw);
      max-width: 250px;
      max-height: 120px;
      transform: translate3d(-50%, -50%, 0) scale(1);
      transition: transform .5s linear;
    }

    &__picture {
      transition: transform .5s linear;

      &_bg {
        transform: scale(1.05);
      }
    }

    &__bottom {
      margin-top: 18px;
    }

    &__subtitle {
      min-height: 20px;
      opacity: 0;
      transition: opacity .4s var(--default-transition-function);
      will-change: opacity;
    }

    &:hover {
      .project-card {
        &__bg {
          opacity: 1;
        }

        &__icon {
          transform: translate3d(-50%, -50%, 0) scale(.95);
        }

        &__picture {

          &_bg {
            transform: scale(1);
          }
        }

        &__subtitle {
          opacity: 1;
        }
      }
    }
  }
}

@include respond-up('medium') {
  .project-card {

  }
}

@include respond('medium') {
  .project-card {
    &__bottom {
      margin-top: 14px;
    }

    &__subtitle {
      margin-top: 5px;
    }

    &__image-wrap {
      height: calc((152 / 768) * 100vw);
    }

    &__icon {
      width: calc((156 / 768) * 100vw);
      height: calc((76 / 768) * 100vw);
    }
  }
}

@include respond-down('medium') {
  .project-card {
    &__subtitle {
      min-height: 32px;
    }
  }
}

@include respond-down('small') {
  .project-card {
    &__bottom {
      margin-top: 8px;
    }

    &__image-wrap {
      height: calc((110 / 375) * 100vw);
    }

    &__icon {
      width: calc((138 / 375) * 100vw);
      height: calc((70 / 375) * 100vw);
    }
  }
}