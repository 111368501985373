.agency-bottom {
  &__images {
    grid-row-gap: 0;
  }

  &__image-wrap {
    display: flex;
  }

  &__picture {
    display: flex;
    position: absolute;

    &_left {
      width: var(--grid-column4);
      left: 0;
    }

    &_center {
      left: 0;
    }

    &_right {
      bottom: 0;
      right: 0;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__left,
  &__right {
    position: relative;
  }

  &__numbers-item-number {
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.02em;
  }

  &__numbers-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: var(--grid-gap);
  }

  &__numbers {
    grid-row-gap: 0;
  }
}

@include respond-up('large') {
  .agency-bottom {
    padding-bottom: 160px;

    &__left {
      grid-column: 1/7;
      height: calc(var(--grid-column6) / (756 / 814));
      max-height: 814px;
    }

    &__right {
      grid-column: 7/13;
      height: calc(var(--grid-column6) / (756 / 814));
      max-height: 814px;
    }

    &__picture {
      &_left {
        height: calc(var(--grid-column4) / (495 / 696));
        top: -123px;
      }

      &_center {
        width: var(--grid-column5);
        height: calc(var(--grid-column5) / (625 / 418));
        top: 204px;
      }

      &_right {
        width: var(--grid-column2);
        height: calc(var(--grid-column2) / (233 / 330));
      }
    }

    &__numbers-item-number {
      font-size: 80px;
    }

    &__numbers-list {
      grid-column: 4/10;
    }

    &__numbers-list {
      grid-row-gap: 89px;
    }
  }
}

@include respond-up('medium') {
  .agency-bottom {
    &__numbers-title {
      grid-column: 1/4;
    }

    &__numbers-item-title {
      margin-top: 20px;
    }
  }
}

@include respond('medium') {
  .agency-bottom {
    padding-bottom: 80px;

    &__left {
      grid-column: 1/6;
      height: calc(var(--grid-column5) / (293 / 540));
    }

    &__right {
      grid-column: 6/13;
      height: calc(var(--grid-column7) / (409 / 540));
    }

    &__picture {
      &_left {
        height: calc(var(--grid-column4) / (244 / 343));
        top: -38px;
      }

      &_center {
        width: var(--grid-column6);
        height: calc(var(--grid-column6) / (347 / 232));
        top: 204px;
      }

      &_right {
        width: var(--grid-column3);
        height: calc(var(--grid-column3) / (166 / 235));
      }
    }

    &__numbers-item-number {
      font-size: 60px;
    }

    &__numbers-list {
      grid-column: 1/13;
      grid-row-gap: 70px;
      margin-top: 50px;
    }
  }
}

@include respond-down('medium') {
  .agency-bottom {

  }
}

@include respond-down('small') {
  .agency-bottom {
    padding-bottom: 50px;

    &__left {
      display: none;
    }

    &__right {
      grid-column: 1/11;
      height: calc(var(--grid-column10) / (346 / 350));
    }

    &__picture {
      &_center {
        width: var(--grid-column8);
        height: calc(var(--grid-column8) / (275 / 184));
        top: 50px;
      }

      &_right {
        width: var(--grid-column4);
        height: calc(var(--grid-column4) / (132 / 185));
      }
    }

    &__numbers-item-number {
      font-size: 50px;
    }

    &__numbers-title,
    &__numbers-list {
      grid-column: 1/11;
    }

    &__numbers-list {
      grid-row-gap: 46px;
      margin-top: 40px;
    }

    &__numbers {
      margin-top: 50px;
    }

    &__numbers-item-title {
      margin-top: 16px;
    }
  }
}