.block-heading {
  border-top: 1px solid var(--primary-color);

  &_light {
    border-color: #fff;

    .block-heading {
      &__title,
      &__heading,
      &__count {
        color: #fff;
      }
    }
  }

  &__count {
    text-align: right;
  }
}

@include respond-up('large') {
  .block-heading {
    &__inner {
      padding-top: 10px;
    }

    &__title {
      grid-column: 1/3;
      padding-top: 25px;
    }

    &__heading {
      grid-column: 4/12;
    }

    &__count {
      padding-top: 25px;
    }
  }
}

@include respond-up('medium') {
  .block-heading {
    &__count {
      grid-column: 12/13;
    }
  }
}

@include respond('medium') {
  .block-heading {
    grid-row-gap: 25px;

    &__inner {
      padding-top: 12px;
      grid-row-gap: 25px;
    }

    &__heading {
      grid-column: 1/13;
    }
  }
}

@include respond-down('medium') {
  .block-heading {
    &__title {
      grid-column: 1/7;
    }

    &__count {
      grid-row: 1;
    }
  }
}

@include respond-down('small') {
  .block-heading {
    grid-row-gap: 20px;

    &__inner {
      padding-top: 6px;
      grid-row-gap: 20px;
    }

    &__heading {
      grid-column: 1/11;
    }

    &__count {
      grid-column: 9/11;
    }
  }
}