.recall-form {
  &__field {
    position: relative;

    &:not(:first-child) {
      margin-top: 8px;
    }
  }

  &__success {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__success-title {
    font-style: normal;
    font-weight: 600;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: #fff;
  }

  &__success-subtitle {
    color: var(--white-color-60);
  }

  &__success-icon {
    display: flex;
    position: absolute;
    right: 0;
    bottom: 0;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@include respond-up('large') {
  .recall-form {
    &__success {
      padding: 40px;
      max-height: 444px;
    }

    &__button {
      margin-top: 40px;
    }

    &__success-title {
      font-size: 32px;
      margin-top: 20px;
    }
  }
}

@include respond-up('medium') {
  .recall-form {
    &__action {
      margin-top: 20px;
    }
  }
}

@include respond('medium') {
  .recall-form {
    &__success {
      padding: 25px;
    }

    &__success-title {
      font-size: 24px;
      margin-top: 18px;
    }

    &__success-icon {
      width: calc((216 / 768) * 100vw);
      height: calc((204 / 768) * 100vw);
    }
  }
}

@include respond-down('medium') {
  .recall-form {
    &__button {
      margin-top: 30px;
    }

    &__success {
      max-height: 319px;
    }
  }
}

@include respond-down('small') {
  .recall-form {
    &__success {
      padding: 18px;
    }

    &__action {
      margin-top: 18px;
    }

    &__success-title {
      font-size: 20px;
      margin-top: 10px;
    }

    &__success-icon {
      width: calc((154 / 375) * 100vw);
      height: calc((145 / 375) * 100vw);
    }
  }
}