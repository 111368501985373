.header {
  &._sticky {
    .header {
      &__inner {
        position: fixed;
        transform: translate3d(0, -100%, 0);
        background-color: var(--primary-color);
        z-index: 1000;
      }
    }
  }

  &._visible {
    .header {
      &__inner {
        transform: translate3d(0, 0, 0);
      }
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: var(--header-height);
    position: absolute;
    width: 100%;
  }

  &__left,
  &__right {
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;
    z-index: 1500;
  }

  &__left {
    justify-content: space-between;
    flex: 1 0 auto;
  }

  &__right {
    justify-content: flex-end;
    flex: 0 0 auto;
  }

  &__info {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__phone {
    display: flex;
    align-items: center;
    height: 100%;

    .button__text {
      @extend .text__main;
    }
  }
}

@include respond-up('large') {
  .header {
    &__nav {
      //margin-left: calc((241 / 1920) * 100vw);
    }

    &__socials {
      margin-left: calc((118 / 1920) * 100vw);
    }

    &__hamburger {
      &.hamburger {
        display: none;
      }
    }
  }
}

@include respond-up('medium') {
  .header {

  }
}

@include respond('medium') {
  .header {
    &__right {
      margin-left: 90px;
    }
  }
}

@include respond-down('medium') {
  .header {
    &__nav {
      &.header-nav {
        display: none;
      }
    }

    &__socials {
      display: none;
    }
  }
}

@include respond-down('small') {
  .header {
    &__phone {
      display: none;
    }
  }
}