.project-modal {
  width: 100vw;
  height: 100vh;

  &._awaiting-animation,
  &._close {
    .project-modal {
      &__inner {
        height: 0;
      }
    }
  }

  &._close {
    .project-modal {
      &__inner {
        transition: height 1.3s cubic-bezier(.645,.045,.355,1);
      }
    }
  }

  &__inner {
    background-color: var(--primary-color);
    height: 100vh;
    transition: height 1s cubic-bezier(.645,.045,.355,1) .15s;
    overflow: hidden;
  }

  &__top {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border-bottom: 1px solid #fff;
      transform: scaleX(0);
    }
  }

  &__top-inner {
    height: var(--modal-closer-width);
  }

  &__main {
    overflow: scroll;
    height: calc(100vh - var(--modal-closer-width));
  }

  &__subtitle,
  &__title {
    color: #fff;
    transform: translate3d(0, 100%, 0);
  }

  &__images {
    display: grid;
    grid-gap: var(--grid-gap);
  }

  &__text-wrap {
    display: flex;
    overflow: hidden;
  }

  &__image-wrap {
    display: flex;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up('large') {
  .project-modal {
    &__main-inner {
      padding: 26px 0 130px;
      grid-row-gap: 80px;
    }

    &__subtitle {
      padding-top: 9px;
      width: var(--grid-column3);
    }

    &__title {
      margin-left: var(--grid-gap);
    }
  }
}

@include respond-up('medium') {
  .project-modal {
    &__images {
      grid-template-columns: repeat(2, 1fr);
    }

    &__image-wrap {
      grid-column: span 1;
      height: calc(var(--grid-column6) / (756 / 460));

      &:nth-child(3n+3) {
        grid-column: 1/3;
        height: calc(var(--grid-column12) / (1540 / 1096));
      }
    }

    &__text-wrap,
    &__images {
      grid-column: 1/13;
    }
  }
}

@include respond('medium') {
  .project-modal {
    &__main-inner {
      padding: 12px 0 100px;
      grid-row-gap: 60px;
    }

    &__subtitle {
      grid-column: 1/7;
    }

    &__title {
      grid-column: 1/13;
      margin-top: 25px;
    }

    &__image-wrap {
      height: calc(var(--grid-column6) / (354 / 215));

      &:nth-child(3n+3) {
        height: calc(var(--grid-column12) / (720 / 512));
      }
    }
  }
}

@include respond-down('medium') {
  .project-modal {
    &__text-wrap {
      flex-direction: column;
    }
  }
}

@include respond-down('small') {
  .project-modal {
    &__images {
      grid-template-columns: 1fr;
    }

    &__main-inner {
      padding: 6px 0 40px;
      grid-row-gap: 40px;
    }

    &__title {
      margin-top: 20px;
    }

    &__text-wrap,
    &__images {
      grid-column: 1/11;
    }

    &__image-wrap {
      height: calc(var(--grid-column10) / (346 / 210));

      &:nth-child(3n+3) {
        height: calc(var(--grid-column10) / (346 / 246));
      }
    }
  }
}