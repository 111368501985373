.index-projects {
  background-color: var(--primary-color);

  &__bottom {
    border-top: 1px solid #fff;
  }

  &__bottom-inner {
    grid-row-gap: 0;
  }

  &__bottom-title {
    color: #fff;
  }

  &__bottom-description {
    color: var(--white-color-60);
  }
}

@include respond-up('large') {
  .index-projects {
    padding-bottom: 160px;

    &__inner {
      margin-top: 120px;
    }

    &__list {
      grid-row-gap: 61px;
    }

    &__item {
      &:nth-child(18n + 2),
      &:nth-child(18n + 3),
      &:nth-child(18n + 10),
      &:nth-child(18n + 11),
      &:nth-child(18n + 18) {
        .project-card__image-wrap {
          height: calc(var(--grid-column6) / (756 / 395));
          max-height: 395px;
        }
      }

      &:nth-child(18n + 1) {
        grid-column: 4/7;
      }

      &:nth-child(18n + 2) {
        grid-column: 7/13;
      }

      &:nth-child(18n + 3),
      &:nth-child(18n + 11) {
        grid-column: 1/7;
      }

      &:nth-child(18n + 4),
      &:nth-child(18n + 7),
      &:nth-child(18n + 12),
      &:nth-child(18n + 15) {
        grid-column: 7/10;
      }

      &:nth-child(18n + 5),
      &:nth-child(18n + 8),
      &:nth-child(18n + 13),
      &:nth-child(18n + 16) {
        grid-column: 10/13;
      }

      &:nth-child(18n + 6),
      &:nth-child(18n + 14) {
        grid-column: 4/7;
      }

      &:nth-child(18n + 9),
      &:nth-child(18n + 17) {
        grid-column: 1/4;
      }

      &:nth-child(18n + 10),
      &:nth-child(18n + 18) {
        grid-column: 4/10;
      }
    }

    &__bottom {
      padding-top: 26px;
    }

    &__bottom-text-wrap {
      grid-column: 1/4;
    }

    &__form {
      grid-column: 7/13;
    }

    &__bottom-description {
      margin-top: 40px;
    }
  }
}

@include respond-up('medium') {
  .index-projects {
    &__bottom {
      margin-top: 120px;
    }
  }
}

@include respond('medium') {
  .index-projects {
    padding-bottom: 80px;

    &__inner {
      margin-top: 60px;
    }

    &__list {
      grid-row-gap: 48px;
    }

    &__item {
      &:nth-child(8n + 2),
      &:nth-child(8n + 3) {
        .project-card__image-wrap {
          height: calc(var(--grid-column8) / (476 / 248));
        }
      }

      &:nth-child(8n + 1),
      &:nth-child(8n + 5) {
        grid-column: 1/5;
      }

      &:nth-child(8n + 2) {
        grid-column: 5/13;
      }

      &:nth-child(8n + 3) {
        grid-column: 1/9;
      }

      &:nth-child(8n + 4),
      &:nth-child(8n) {
        grid-column: 9/13;
      }

      &:nth-child(8n + 6),
      &:nth-child(8n + 7) {
        grid-column: 5/9;
      }
    }

    &__bottom-text-wrap,
    &__form {
      grid-column: 1/13;
    }

    &__form {
      margin-top: 50px;
    }

    &__bottom-description {
      width: var(--grid-column6);
    }

    &__bottom {
      padding-top: 20px;
    }
  }
}

@include respond-down('medium') {
  .index-projects {
    &__bottom-description {
      margin-top: 20px;
    }
  }
}

@include respond-down('small') {
  .index-projects {
    padding-bottom: 50px;

    &__inner {
      margin-top: 40px;
    }

    &__list {
      grid-row-gap: 37px;
    }

    &__item {
      &:nth-child(even) {
        grid-column: 1/6;
      }

      &:nth-child(odd) {
        grid-column: 6/11;
      }

      &:first-child {
        grid-column: 1/6;
      }

      &:nth-child(8n+3),
      &:nth-child(8n+2) {
        grid-column: 1/11;

        .project-card__image-wrap {
          height: calc(var(--grid-column10) / (346 / 180));
        }
      }
    }

    &__bottom-text-wrap,
    &__form {
      grid-column: 1/11;
    }

    &__form {
      margin-top: 40px;
    }

    &__bottom {
      padding-top: 12px;
      margin-top: 60px;
    }
  }
}