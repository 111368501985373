.footer-nav {
  &__link {
    .button__text {
      @extend .text__main;
    }
  }
}

@include respond-up('large') {
  .footer-nav {
    margin: -20px;

    &__link {
      padding: 20px;
    }
  }
}

@include respond-up('medium') {
  .footer-nav {
    display: flex;
    align-items: center;
  }
}

@include respond('medium') {
  .footer-nav {
    margin: -15px;

    &__link {
      padding: 15px;
    }
  }
}

@include respond-down('medium') {
  .footer-nav {

  }
}

@include respond-down('small') {
  .footer-nav {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: var(--grid-gap);
    margin: -5px;

    &__link {
      padding: 5px;
    }
  }
}