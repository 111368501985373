.arrows {
  display: flex;
  align-items: center;

  &_dark {
    .arrow__icon {
      width: 34px;
      height: 25px;

      svg {
        path {
          stroke: var(--primary-color);
        }
      }
    }
  }
}

@include respond-up('large') {
  .arrows {

  }
}

@include respond-up('medium') {
  .arrows {
    &__arrow {
      padding: 15px;
      margin: -15px;

      &_left {
        margin-right: 0;
      }

      &_right {
        margin-left: 0;
      }
    }
  }
}

@include respond('medium') {
  .arrows {

  }
}

@include respond-down('medium') {
  .arrows {

  }
}

@include respond-down('small') {
  .arrows {
    &__arrow {
      padding: 10px;
      margin: -10px;

      &_left {
        margin-right: 0;
      }

      &_right {
        margin-left: 0;
      }
    }
  }
}