.experience-item {
  border-bottom: 1px solid var(--primary-color);
  display: grid;
  grid-template-columns: var(--grid-gap);

  &:first-child {
    border-top: 1px solid var(--primary-color);
  }

  &__description {
    color: var(--primary-color-60);
    margin-top: 8px;
  }

  &__number {
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.02em;
  }
}

@include respond-up('large') {
  .experience-item {
    padding: 11px 0 30px;
    grid-template-columns: repeat(2, 1fr);

    &__number {
      font-size: 80px;
      line-height: 135%;
    }

    &__text-wrap {
      padding-top: 19px;
    }
  }
}

@include respond-up('medium') {
  .experience-item {

  }
}

@include respond('medium') {
  .experience-item {
    grid-template-columns: var(--grid-column2) var(--grid-column5);

    &__number {
      font-size: 70px;
    }
  }
}

@include respond-down('medium') {
  .experience-item {
    padding: 9px 0 20px;

    &__number {
      line-height: 120%;
    }

    &__text-wrap {
      padding-top: 11px;
    }
  }
}

@include respond-down('small') {
  .experience-item {
    grid-template-columns: var(--grid-column2) var(--grid-column8);

    &__number {
      font-size: 60px;
    }
  }
}