.review-card {
  position: relative;
  will-change: transform;
  //background-color: var(--primary-color);
  //transition: all 1s ease-in-out;

  &__title,
  &__position,
  &__text {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;
  }

  &__title {
    font-style: normal;
    font-weight: 600;
    line-height: 145%;
    letter-spacing: -0.02em;
    color: #fff;
  }

  &__position {
    color: var(--white-color-60);
  }

  &__text {
    color: #fff;
  }

  &__picture {
    display: flex;
    overflow: hidden;
    border-radius: 50%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__author-wrap {
    display: flex;
  }

  &__icon {
    position: absolute;
    top: 26px;
    right: 22px;
    z-index: 25;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@include respond-up('large') {
  .review-card {
    min-height: 612px;
    padding: 0 var(--grid-spacer-and-indent);

    &__inner {
      padding: 26px 0 112px;
    }

    &__title {
      font-size: 22px;
    }

    &__picture {
      width: 90px;
      height: 90px;
    }

    &__text {
      margin-top: 82px;
      max-width: 1142px;
    }

    &__author-info {
      margin-left: 25px;
    }

    &__position {
      margin-top: 8px;
    }
  }
}

@include respond-up('medium') {
  .review-card {
    &__author-wrap {
      align-items: center;
    }
  }
}

@include respond('medium') {
  .review-card {
    &__title {
      font-size: 20px;
    }

    &__text {
      margin-top: 56px;
    }

    &__author-info {
      margin-left: 20px;
    }
  }
}

@include respond-down('medium') {
  .review-card {
    &__picture {
      width: 70px;
      height: 70px;
    }

    &__position {
      margin-top: 6px;
    }

    &__icon {
      width: 70px;
      height: 70px;
      top: 0;
      right: 0;
    }
  }
}

@include respond-down('small') {
  .review-card {
    &__title {
      font-size: 18px;
    }

    &__text {
      margin-top: 40px;
    }

    &__author-wrap {
      flex-direction: column;
    }

    &__author-info {
      margin-top: 15px;
    }
  }
}