.counter {
  display: flex;
  align-items: center;

  .swiper-wrapper{
    height: 22px;
  }

  &__current-item {
    color: #fff;
  }

  &__text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 145%;
    text-align: right;
    letter-spacing: -0.02em;
    color: #fff;
  }

  &__delimiter {
    display: flex;
    padding: 0 15px;

    &::before {
      content: '';
      height: 1px;
      background-color: #fff;
    }
  }

  &_dark {
    .counter {
      &__current-item,
      &__text {
        color: var(--primary-color);
      }

      &__delimiter {
        &:before {
          background-color: var(--primary-color);
        }
      }
    }
  }
}

@include respond-up('large') {
  .counter {

  }
}

@include respond-up('medium') {
  .counter {
    &__delimiter {
      &::before {
        width: 100px;
      }
    }
  }
}

@include respond('medium') {
  .counter {

  }
}

@include respond-down('medium') {
  .counter {

  }
}

@include respond-down('small') {
  .counter {
    &__delimiter {
      &::before {
        width: 70px;
      }
    }
  }
}