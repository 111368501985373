.index-reviews {
  background-color: var(--primary-color);

  &__slider-wrap {
    position: relative;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__list {
    position: relative;
  }

  &__item {

  }
}

@include respond-up('large') {
  .index-reviews {
    &__item {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }

    &__nav {
      position: absolute;
      z-index: 150;
      left: var(--grid-spacer-and-indent);
      right: var(--grid-spacer-and-indent);
      bottom: 36px;
      height: 34px;
      width: var(--grid-column11);
    }

    &__slider-wrap {
      margin-top: 120px;
      min-height: 612px;
    }

    &__list {
      min-height: 612px;
    }

    &__slider {
      flex: 1 0 auto;
      cursor: grab;
    }

    &__item {
      background-color: var(--background-color-gray);
      width: calc(100vw - 114px);
      transition: transform .467s, background-color .467s;
      transition-timing-function: cubic-bezier(.55,0,.1,1);
      transform: translateX(114px);
      will-change: transform;

      &._active {
        background-color: var(--primary-color);
        transform: translateX(0);
      }

      &._next {
        background-color: var(--background-color-dark);
        transform: translateX(57px);
      }

      &._out {
        background-color: var(--primary-color);
        transform: translateX(-100%);
        z-index: 100 !important;
      }
    }
  }
}

@include respond-up('medium') {
  .index-reviews {

  }
}

@include respond('medium') {
  .index-reviews {
    &__slider-wrap {
      margin-top: 60px;
      padding: 25px 0;
      min-height: 578px;
    }
  }
}

@include respond-down('medium') {
  .index-reviews {
    &__list {
      &.swiper-wrapper {
        width: calc(100vw - (var(--grid-spacer) * 2));
      }
    }

    &__item {
      background-color: var(--primary-color);
    }

    &__nav {
      padding: 0 var(--grid-spacer);
      margin-top: var(--grid-spacer);
    }
  }
}

@include respond-down('small') {
  .index-reviews {
    &__slider-wrap {
      margin-top: 44px;
      padding: 18px 0;
      min-height: 672px;
    }
  }
}