.footer {
  background-color: var(--primary-color);

  &__phone {
    .button__text {
      @extend .text__main;
    }
  }

  &__invisible-svg {
    position: absolute;
    opacity: 0;
    pointer-events: none;

    svg {
      path {
        transition: var(--default-transition);
        animation: spin 10s linear 0s infinite;
      }
    }
  }

  &__info-wrap {
    display: flex;
  }

  &__info {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__developer {
    color: #fff;
    display: flex;
    align-items: center;
  }

  &__phone-wrap,
  &__developer-wrap,
  &__policy-wrap {
    display: flex;
    align-items: center;
  }

  &__nav-wrap {
    display: flex;
  }

  &__socials-wrap {
    display: flex;
  }

  &__email {
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #fff;
  }

  &__email-top {
    display: flex;
    align-items: center;
  }

  &__email-icon {
    display: flex;
    overflow: hidden;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@include respond-up('large') {
  .footer {
    padding: 80px 0 73px;

    &__inner {
      grid-row-gap: 124px;
    }

    &__phone-wrap {
      grid-column: 1/3;
    }

    &__nav-wrap {
      grid-column: 7/13;
    }

    &__policy-wrap {
      //margin-left: calc(var(--grid-column) + (var(--grid-gap) * 2));
    }

    &__info-wrap {
      grid-column: 6/10;
      align-items: flex-end;
    }

    &__info {
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &__developer-wrap {
      margin-right: 20px;
    }

    &__socials-wrap {
      grid-column: 11/13;
      align-items: flex-end;
    }

    &__email-wrap {
      grid-column: 1/5;
    }

    &__email {
      font-size: 55px;

      &:hover {
        .footer__email-icon svg {
          &:first-child {
            transform: translate3d(0, 100%, 0);
          }

          &:last-child {
            transform: translate3d(0, 0, 0);
          }
        }
      }
    }

    &__email-icon {
      margin-left: 16px;
      position: relative;

      svg {
        transition: transform .4s var(--default-transition-function);

        &:last-child {
          position: absolute;
          top: 0;
          left: 0;
          transform: translate3d(0, -100%, 0);
        }
      }
    }

    &__email-title {
      margin-top: 7px;
    }
  }
}

@include respond-up('medium') {
  .footer {
    &__socials-wrap {
      justify-content: flex-end;
    }

    &__nav-wrap {
      justify-content: flex-end;
    }
  }
}

@include respond('medium') {
  .footer {
    padding: 50px 0 46px;

    &__email {
      font-size: 35px;
    }

    &__email-title {
      margin-top: 6px;
    }

    &__phone-wrap {
      grid-column: 1/4;
    }

    &__nav-wrap {
      grid-column: 4/13;
    }

    &__email-wrap {
      grid-column: 1/6;
      margin-top: 120px;
    }

    &__socials-wrap {
      grid-column: 9/13;
      grid-row: 2/3;
      margin-top: 174px;
    }

    &__info-wrap {
      grid-column: 1/8;
      margin-top: 40px;
    }

    &__policy-wrap {
      margin-left: 67px;
    }

    &__email-icon {
      height: 30px;
    }
  }
}

@include respond-down('medium') {
  .footer {
    &__inner {
      grid-row-gap: 0;
    }

    &__email-icon {
      margin-left: 9px;

      svg {
        &:last-child {
          display: none;
        }
      }
    }

    &__policy-wrap {
      justify-content: flex-end;
    }
  }
}

@include respond-down('small') {
  .footer {
    padding: 40px 0 38px;

    &__email {
      font-size: 28px;
    }

    &__email-title {
      margin-top: 4px;
    }

    &__phone-wrap {
      grid-column: 1/6;
    }

    &__nav-wrap {
      grid-column: 1/11;
      margin-top: 40px;
    }

    &__nav {
      width: 100%;
    }

    &__email-wrap {
      grid-column: 1/7;
      margin-top: 60px;
    }

    &__socials-wrap {
      grid-column: 1/8;
      grid-row: 4/5;
      margin-top: 25px;
    }

    &__info-wrap {
      grid-column: 1/11;
      margin-top: 30px;
    }

    &__info {
      justify-content: space-between;
    }

    &__email-icon {
      height: 23px;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}