.index-contacts {
  &__label {
    color: var(--gray-color);
  }

  &__address {
    grid-area: address;
  }

  &__e-mail {
    grid-area: email;
  }

  &__social {
    grid-area: social;
  }

  &__phone {
    grid-area: phone;
  }

  &__messengers {
    grid-area: messengers;
  }

  &__link {
    margin-bottom: 4px;
    position: relative;

    &_viber,
    &_whatsapp,
    &_telegram {
      position: relative;
      padding-left: 23px;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate3d(0, -50%, 0);
        width: 8px;
        height: 8px;
        border-radius: 100%;
      }
    }

    &_viber {
      &::before {
        background-color: var(--purple-color);
      }
    }

    &_whatsapp {
      &::before {
        background-color: var(--green-color-whatsapp);
      }
    }

    &_telegram {
      &::before {
        background-color: var(--blue-color-telegram);
      }
    }
  }

  &__link-title {
    text-transform: capitalize;
    color: var(--primary-color);
  }
}

@include respond-up('large') {
  .index-contacts {
    padding: 120px 0 150px;

    &__main {
      grid-template-areas:
              "address email social"
              "phone messengers social";
      grid-template-columns: repeat(3, var(--grid-column3));
      grid-row-gap: 83px;
      padding-left: calc(var(--grid-column3) + var(--grid-gap));
    }

    &__label {
      margin-bottom: 18px;
    }

    &__link-title {
      transition: color .4s;
    }

    &__link {
      &:hover {
        .index-contacts__link-title {
          color: var(--primary-color-40);
        }
      }
    }

    &__inner {
      margin-top: 120px;
    }
  }
}

@include respond('medium') {
  .index-contacts {
    padding: 58px 0 80px;

    &__main {
      grid-template-areas:
              "address email social"
              "phone messengers social";
      grid-template-columns: repeat(3, var(--grid-column4));
      grid-row-gap: 55px;
    }

    &__label {
      margin-bottom: 16px;
    }

    &__inner {
      margin-top: 60px;
    }
  }
}

@include respond-down('small') {
  .index-contacts {
    padding: 33px 0 50px;

    &__main {
      grid-template-areas:
              "address address"
              "phone email"
              "messengers social";
      grid-template-columns: repeat(2, var(--grid-column5));
      grid-row-gap: 40px;
    }

    &__label {
      margin-bottom: 8px;
    }

    &__inner {
      margin-top: 40px;
    }
  }
}
